import React from "react";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import { StaticImage } from "gatsby-plugin-image";
import MensHealthTestimonials from "../../components/MensHealthTestimonials";
import Badge from "../../components/badge";
import GetStartedButton from "../../components/button";
import { Link } from "gatsby";
import { P, TextBlockHeading, PageHeading } from "../../components/typography";

const AboutUs = () => {
  return (
    <Layout map={false}>
      <SEO
        title="Premier ED Treatment: About Multnomah Medical Clinic"
        description="Premier ED treatment provider, Multnomah Medical Clinic is dedicated to offering the latest breakthroughs in ED treatment available."
      />
      <div className="flex justify-center text-center">
        <div className="text-left px-6 xs:px-12 sm:px-8 md:px-8 lg:px-32 w-full lg:max-w-[80rem] gap-4">
          <div className="sm:grid sm:grid-cols-5 grid-cols-1 rounded-lg gap-4 mb-10">
            <div
              className="col-span-2 w-full flex row-1-thing mb-4"
              style={{ minHeight: "300px" }}
            >
              <StaticImage
                width="100%"
                aspectRatio={1 / 1}
                objectFit="cover"
                layout="constrained"
                src="../../images/emtt_feet.jpg"
                className="w-full rounded-lg"
                style={{ minHeight: "300px" }}
              />
            </div>
            <div className="col-span-3 row-1-thing">
              <h1 className="text-3xl text-gray-900 font-semibold leading-snug">
                Advanced Treatment Technologies<br></br> at Multnomah Medical
                Clinic
              </h1>
              <div className="flex gap-3 mt-2 mb-2 items-center flex-wrap">
                <Badge classes="bg-blue-300 text-no-wrap">
                  Radial shockwave
                </Badge>
                <Badge classes="bg-blue-300">Focused shockwave</Badge>
                <Badge classes="bg-blue-300">EMTT</Badge>
                <div
                  className="font-semibold hidden sm:visible"
                  style={{ fontSize: "14px" }}
                >
                  + MORE
                </div>
              </div>
              <div className="text-lg mb-6">
                At Multnomah Medical, we offer a comprehensive range of
                cutting-edge treatment options to help our patients find relief
                from pain and chronic conditions.
              </div>
              <div className="text-lg mb-6">
                Our state-of-the-art technologies include radial shockwave
                therapy, focused shockwave therapy, and EMTT (Extracorporeal
                Magnetotransduction Therapy), allowing us to provide targeted,
                non-invasive solutions tailored to your unique needs.
              </div>
              <GetStartedButton linkTo="/contact-us">
                Book an appointment
              </GetStartedButton>
            </div>
          </div>
        </div>
      </div>

      <div className="">
        <div className="text-left px-6 xs:px-12 sm:px-8 md:px-8 lg:px-32 w-full lg:max-w-[80rem] gap-4  pt-4 pb-10">
          <div className="mb-8">
            <TextBlockHeading wide>Radial Shockwave Therapy</TextBlockHeading>
            <div>
              Radial shockwave therapy is a powerful treatment option that uses
              high-energy acoustic waves to stimulate healing and reduce pain in
              the body. This therapy is particularly effective for treating soft
              tissue injuries, tendinopathies, and other musculoskeletal
              conditions.{" "}
              <div className="mt-0">
                <br></br>Benefits of radial shockwave therapy:
                <br></br>- Non-invasive and drug-free Stimulates the body's
                natural healing processes<br></br>- Reduces pain and
                inflammation Improves blood circulation and tissue regeneration
                <br></br>- Quick treatment with no downtime
              </div>
            </div>
          </div>
          <div className="mb-8">
            <TextBlockHeading wide>Focused Shockwave Therapy</TextBlockHeading>
            <div>
              Focused shockwave therapy is a highly precise treatment that
              delivers concentrated shockwaves to specific areas of the body.
              This targeted approach makes it ideal for treating chronic pain
              conditions, such as plantar fasciitis, shoulder pain, and knee
              pain. <div className="mt-4"></div>Advantages of focused shockwave
              therapy:
              <br></br> - Pinpoint accuracy for treating specific pain points{" "}
              <br></br>- Promotes healing and tissue repair <br></br>- Reduces
              chronic pain and stiffness Non-surgical and medication-free{" "}
              <br></br>- Short treatment sessions with minimal discomfort
            </div>
          </div>
          <div>
            <TextBlockHeading wide>EMTT</TextBlockHeading>
            <div>
              EMTT is the latest addition to our advanced treatment portfolio.
              This innovative therapy uses magnetic fields to stimulate cellular
              repair and regeneration, offering a new solution for patients with
              musculoskeletal pain and various chronic conditions.{" "}
              <div className="mt-4">
                Key features of EMTT: <br></br>- Non-invasive treatment without
                surgery or medication Targeted relief for pain and inflammation{" "}
                <br></br>- Brief sessions that fit conveniently into your
                schedule Synergistic with shockwave therapy for enhanced
                effectiveness <br></br>- Tailored treatment plans to meet your
                unique needs
              </div>
            </div>
          </div>
        </div>
        {/*<div className="bg-blue-50 font-semibold pt-4 pb-10 text-2xl text-left px-6 xs:px-12 sm:px-8 md:px-8 lg:px-32 w-full lg:max-w-[80rem] gap-4">
          Frequently asked questions
        </div>*/}
      </div>
      <div className="text-left w-full">
        <MensHealthTestimonials />
      </div>
    </Layout>
  );
};

export default AboutUs;

//const vidRef = useRef(null)
//const [mouseInIframe, setMouseInIframe] = useState(false)

// Log video interactions
/*useEffect(() => {
    const setMouseIn = () => setMouseInIframe(true)
    const setMouseOut = () => setMouseInIframe(false)
    const logVideoClick = () => {
      if (mouseInIframe === true) {
        logEvent(firebaseContext.firebase, "video_interaction")
        typeof window !== "undefined" &&
          window.gtag('event', 'video_play', { send_to: "UA-154763728-1"})
      }
    }
    if (vidRef.current === null) {
      return
    } else {
      const localVidRef = vidRef.current
      localVidRef.addEventListener("mouseenter", setMouseIn)
      localVidRef.addEventListener("mouseout", setMouseOut)
      window.addEventListener("blur", logVideoClick)
      return () => {
        localVidRef.removeEventListener("mouseenter", setMouseIn)
        localVidRef.removeEventListener("mouseout", setMouseOut)
        window.removeEventListener("blur", logVideoClick)
      }
    }
  }, [vidRef, mouseInIframe])*/
